<script setup>
import { computed, defineProps, defineEmits } from 'vue'
const emit = defineEmits(['updateMobile', 'loginClicked'])
const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  mobile: {
    type: String
  }
})
const username = computed({
  get() {
    return props.mobile
  },
  set(value) {
    emit('updateMobile', value)
  }
})
</script>

<template>
  <form novalidate class="login-form" @submit.prevent="$emit('login-clicked')">
    <BaseInput
      light-theme
      phone-number
      start-left
      style="margin: 0 0 30px"
      label="شماره موبایل"
      star-label
      :charCountToRemove="12"
      v-model="username"
    />
    <div class="credit-buttons">
      <button type="submit" class="button button--login">
        <span class="button__text">ورود</span>
        <spinner v-if="loading" />
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
// Change in common css
.credit-buttons {
  margin: 0;
  flex-direction: column;
}
.button--login {
  width: 90%;
  background-color: #c69d4e;
  margin: 0;
  .button__text {
    color: #000;
  }
}
</style>
